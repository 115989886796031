/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.4.1/dist/jquery.min.js
 * - /npm/underscore@1.9.2/underscore-min.min.js
 * - /npm/backbone@1.4.0/backbone-min.min.js
 * - /npm/@popperjs/core@2.5.4/dist/umd/popper.min.js
 * - /npm/bootstrap@4.5.2/dist/js/bootstrap.min.js
 * - /npm/cropperjs@1.5.11/dist/cropper.min.js
 * - /npm/bxslider@4.2.14/dist/jquery.bxslider.min.js
 * - /npm/jquery-datetimepicker@2.5.21/build/jquery.datetimepicker.full.min.js
 * - /npm/js-cookie@2.2.1/src/js.cookie.min.js
 * - /npm/owl.carousel@2.3.4/dist/owl.carousel.min.js
 * - /npm/quill@1.3.7/dist/quill.min.js
 * - /npm/localforage@1.9.0/dist/localforage.min.js
 * - /npm/select2@4.1.0-rc.0/dist/js/select2.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
